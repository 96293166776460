import { inject, Injectable } from "@angular/core";
import { Auth, authState, getIdTokenResult, GoogleAuthProvider, idToken, signInWithPopup, User } from "@angular/fire/auth";
import { map, Observable, switchMap } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private auth = inject(Auth);

  readonly isLoggedIn = this.idToken().pipe(
    map(idToken => {
      return idToken != null
    })
  );

  readonly isAuthorized = this.authState().pipe(
    switchMap(async (user) => {
      if (!user) return false;
      const idTokenResult = await getIdTokenResult(user);
      if (idTokenResult.claims && idTokenResult.claims['authorized']) {
        return true;
      }
      return false;
    })
  );

  authState(): Observable<User | null> {
    return authState(this.auth);
  }

  idToken(): Observable<string | null> {
    return idToken(this.auth);
  }

  signInWithGoogle() {
    const provider = new GoogleAuthProvider();
    const credential = signInWithPopup(this.auth, provider);
    return credential;
  }

  signOut() {
    return this.auth.signOut();
  }
}
